.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 200px;
    height: 40px;
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  .back-to-top:hover {
    background-color: #555;
  }
  