html {
  scroll-behavior: smooth;
}


body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #000000;
  }
  
  .App-header {
    text-align: center;
    padding: 50px;
    background-color: #282c34;
    color: white;
  }
  
  