.hero {
    position: relative;
    text-align: center;
    color: white;
  }
  
  .hero-image {
    width: 800px;
    height: 400px;
    object-fit:contain;
    filter: brightness(100%);
  }.hero-section {
    text-align: center;
    padding-top: 100px;
    background: black; /* Set background to black */
    color: white; /* Set text color to white */
  }
  
  .logos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .hero-logo {
    max-width: 500px; /* Adjust size as needed */
    height: auto;
  }
  
  .hero-section h1 {
    margin: 20px 0 10px;
  }
  
  .hero-section p {
    margin: 0;
  }
  
  
  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  