footer {
  text-align: center;
  padding: 20px;
  background: #222;
  color: white;
}

.footer-logo {
  width: 150px; /* Adjust the size as needed */
  height: auto;
  margin-top: 10px;
}
