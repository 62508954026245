.services {
  background-color: #000000;
  padding: 20px;
  text-align: center; /* Center text */
}

.container {
  max-width: 100%;
  margin: 0 auto;
}

h2, h3 {
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: 700; /* Thicker font weight */
}

p {
  color: #ffffff;
  margin-bottom: 15px;
  font-weight: 500; /* Thicker font weight */
}

.services-content {
  text-align: center;
}

.services-section {
  margin: 0 auto;
  margin-bottom: 20px;
}
