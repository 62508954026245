.gallery {
  width: 100%;
  padding: 5px;
  color: black;
  background-color: black; /* Black background for the gallery */
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px; /* Space between images */
}

.gallery-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-image:hover {
  transform: scale(1.05);
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8); /* Optional: dark overlay for contrast */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden; /* Prevents scrolling */
}

.modal-content {
  position: relative;
  max-width: 80vw; /* Limits the modal width to 80% of the viewport width */
  max-height: 80vh; /* Limits the modal height to 80% of the viewport height */
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content img {
  width: auto; /* Maintain the aspect ratio */
  height: auto; /* Maintain the aspect ratio */
  max-width: 100%; /* Ensures the image doesn't overflow the modal */
  max-height: 100%; /* Ensures the image doesn't overflow the modal */
  display: block;
  margin: auto; /* Centers the image within the modal */
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  color: white; /* Visible against the image */
  background-color: rgba(0, 0, 0, 0.5); /* Optional background for visibility */
  padding: 5px;
  border-radius: 50%;
}

/* Navigation Buttons */
.prev, .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  z-index: 1001;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
