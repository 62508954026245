.about-us {
  background-color: #000000;
  padding: 20px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

h2 {
  color: #333;
  margin-bottom: 10px;
  font-weight: 700; /* Thicker font weight */
}

p {
  color: #666;
  margin-bottom: 15px;
  font-weight: 500; /* Thicker font weight */
  font-size: larger;
}
